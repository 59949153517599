(function ($) {
  function _launch(alreadyExists, $el) {
    var $el = $el;
    var rendered = site.template.get({
      name: 'product_fave_overlay',
      data: { already_exists: alreadyExists }
    });

    generic.overlay.launch({
      content: rendered,
      width: 391,
      height: 304,
      cssClass: 'product-fave-overlay-cbox',
      onClosed: function () {
        $el.focus();
      }
    });
    if ($('body').hasClass('device-mobile')) {
      $.colorbox.resize();
    }
  }
  $(document).on('addToWishlist.exists', function (event, flag, $el) {
    _launch(true, $el);
  });

  $(document).on('addToWishlist.success', function (event, flag, $el) {
    _launch(false, $el);
  });
})(jQuery);
